export function TwilioLogo() {
  return (
    <svg width="33.4" height="40.4" viewBox="0 0 33 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.3">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#aeb2c1"
          d="M22.7 5.7a5.7 5.7 0 1 0-5.7 5.7 5.7 5.7 0 0 0 5.7-5.7"
        />

        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.2 12.8A9.8 9.8 0 0 1 17 15a9.4 9.4 0 0 1-3.3-.6 9.3 9.3 0 0 1-2.4-1.3 5.1 5.1 0 0 0-.8-.6 6.2 6.2 0 0 0-3.7-1.2A6.4 6.4 0 0 0 4 12a6.7 6.7 0 0 0-1.6 11.4 5.3 5.3 0 0 0 5.7.6L6.8 22l9.5-.3-6 7.3-.9-2.4a6.8 6.8 0 0 0-4 6v.2a7.8 7.8 0 0 0 13.8 4.8 6.2 6.2 0 0 0 1.6-3V34l.4-1.8a11.8 11.8 0 0 1 8-9.1 6 6 0 0 0 4.2-5.6 6.2 6.2 0 0 0-6.1-6.3h-.1a6 6 0 0 0-4 1.5"
          fill="#aeb2c1"
        />
      </g>
    </svg>
  );
}
