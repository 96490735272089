export function Logo() {
  return (
    <svg width="161" height="200.5" viewBox="0 0 400.1 500.5" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.1">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="white"
          d="M266.1 67a67 67 0 1 0-67 67 66.9 66.9 0 0 0 67-67"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="white"
          d="M271.4 149.3a114.1 114.1 0 0 1-72.4 26.1 110 110 0 0 1-38.5-6.8 108.8 108.8 0 0 1-28-14.7 59.8 59.8 0 0 0-9-7A72.7 72.7 0 0 0 79 132.6a74.4 74.4 0 0 0-31.2 7.4 78.9 78.9 0 0 0-18.1 134.3 62 62 0 0 0 66 6.8l-16.3-25 111.1-3.6v.2l-70.5 85.4-10-28.2-1 .4A79.3 79.3 0 0 0 64 380v2c0 50 40.6 90.4 90.7 90.4a91 91 0 0 0 70.4-33.4 72.2 72.2 0 0 0 18.3-35.8l.8-4 4.2-21.7a138.5 138.5 0 0 1 93.7-106 70.5 70.5 0 0 0 48.9-65.3v-1.2a72.5 72.5 0 0 0-71.8-72.3h-1a70.6 70.6 0 0 0-46.9 16.5"
        />
      </g>
    </svg>
  );
}
